import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';

// Ui-kit
import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import RadioButtonComponent from 'ui-kit/radio-button/radio-button';
import Text from 'ui-kit/text/text';

// Components
import { stateOptions } from 'const/options';

// Types
import { MembershipRegistrationFormValidateInsuranceIdProps } from 'types/membership';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

import './membership-registration-insurance-additional-info.style.scss';

interface StateChangeEvent {
    key: string;
    option: {
        key: string;
        label: string;
        value: string;
    };
}

enum CONTACT_METHOD_FORM {
    PHONE = 2,
    EMAIL = 1
}

const MembershipRegistrationInsuranceAdditionalInfo = ({
    validateStatus,
    isBusy
}: MembershipRegistrationFormValidateInsuranceIdProps) => {
    const { t } = useTranslation();
    const globalLink = useGlobalLink();

    const { values, touched, errors, handleBlur, handleChange, setFieldTouched, setFieldValue, validateField } =
        useFormikContext<any>();
    const hasAdditionalFormRequest = validateStatus === 'information-request';

    const handleInputChange = async (fieldName: string, value: any) => {
        await setFieldValue(fieldName, value);
        validateField(fieldName);
    };

    useEffect(() => {
        if (hasAdditionalFormRequest) {
            setFieldValue('hasAdditionalInfo', true, false);
        }
    }, [hasAdditionalFormRequest]);

    if (!hasAdditionalFormRequest) {
        return null;
    }

    return (
        <div
            id="membership-registration-insurance-additional-info"
            className="membership-registration-insurance-additional-info-content"
        >
            <div className="membership-registration-insurance-additional-info">
                <div className="membership-registration-insurance-additional-info-header">
                    <div>
                        <div className="membership-registration-insurance-additional-info-header-pill"></div>
                        <div className="personal-info-title">
                            {t('membership.getStarted.additionalRequest.sections.additionalInfo.title')}
                        </div>
                    </div>
                </div>

                <div className="membership-registration-insurance-additional-info-form">
                    <div className="membership-registration-insurance-additional-info-form-content">
                        <Text
                            name="address1"
                            label={t('membership.shipping.form.address1')}
                            onChange={(e) => handleInputChange('address1', e.target.value)}
                            onBlur={(e) => {
                                setFieldTouched('address1', true);
                                handleBlur(e);
                            }}
                            showErrorsText={true}
                            errors={
                                errors?.address1
                                    ? t('forms.membership.shippingInformation.errors.requiredAddress')
                                    : undefined
                            }
                            touched={(!!errors?.address1 || touched.address1) as boolean}
                            value={values?.address1}
                            onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.address1'))}
                        />

                        <Text
                            name="address2"
                            label={t('membership.shipping.form.address2')}
                            onChange={(e) => handleInputChange('address2', e.target.value)}
                            onBlur={(e) => {
                                setFieldTouched('address2', true);
                                handleBlur(e);
                            }}
                            touched={(!!errors?.address2 || touched.address2) as boolean}
                            value={values?.address2}
                            onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.address2'))}
                        />

                        <div className="membership-registration-insurance-additional-info-form-content-inline">
                            <Text
                                name="city"
                                label={t('membership.shipping.form.city')}
                                onChange={(e) => handleInputChange('city', e.target.value)}
                                onBlur={(e) => {
                                    setFieldTouched('city', true);
                                    handleBlur(e);
                                }}
                                showErrorsText={true}
                                errors={
                                    errors?.city
                                        ? t('forms.membership.shippingInformation.errors.requiredCity')
                                        : undefined
                                }
                                touched={(!!errors?.city || touched.city) as boolean}
                                value={values?.city}
                                onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.city'))}
                            />
                            <Field
                                id="state"
                                name="state"
                                options={stateOptions}
                                component={FormSelect}
                                value={values.state}
                                placeholder={t('membership.shipping.form.state')}
                                touched={!!errors?.state || touched.state}
                                onChange={(e: StateChangeEvent) => handleInputChange('state', e.option.value)}
                                onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.state'))}
                                showErrorsText={true}
                                errors={
                                    errors?.state
                                        ? t('forms.membership.shippingInformation.errors.requiredState')
                                        : undefined
                                }
                            />
                            <Text
                                name="zipcode"
                                label={t('membership.shipping.form.zipCode')}
                                className="full-width-field-mobile"
                                onChange={(e) => handleInputChange('zipcode', e.target.value)}
                                onBlur={(e) => {
                                    setFieldTouched('zipcode', true);
                                    handleBlur(e);
                                }}
                                errors={
                                    errors?.zipcode
                                        ? errors?.zipcode === 'min' || errors?.zipcode === 'onlyNumbers'
                                            ? t(`membership.getStarted.form.errors.zipCode.invalid`)
                                            : t('forms.errorMessages.requiredField', {
                                                  label: t(`pages.profile.addAddress.zipCode`)
                                              })
                                        : undefined
                                }
                                touched={(!!errors?.zipcode || touched.zipcode) as boolean}
                                value={values?.zipcode}
                                maxLength={5}
                                onFocus={() => globalLink.handleFieldFocus(t('membership.shipping.form.zipCode'))}
                            />
                        </div>

                        <PhoneNumberText
                            name="phoneNumber"
                            label={t('membership.contact.phoneNumber')}
                            onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                            errors={
                                errors?.phoneNumber
                                    ? t(
                                          'membership.getStarted.additionalRequest.sections.additionalInfo.form.phoneError'
                                      )
                                    : undefined
                            }
                            touched={(!!errors?.phoneNumber || touched.phoneNumber) as boolean}
                            value={values?.phoneNumber}
                            defaultValue={values?.phoneNumber}
                            countryCode={'US'}
                            onFocus={() => globalLink.handleFieldFocus(t('membership.contact.phoneNumber'))}
                            onBlur={(e) => {
                                setFieldTouched('phoneNumber', true);
                                handleBlur(e);
                            }}
                            autoComplete="off"
                        />

                        <Text
                            name="email"
                            label={t('membership.contact.email')}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            onBlur={(e) => {
                                setFieldTouched('email', true);
                                handleBlur(e);
                            }}
                            touched={(!!errors?.email || touched.email) as boolean}
                            value={values?.email}
                            defaultValue={values?.email}
                            errors={
                                errors?.email
                                    ? t(
                                          'membership.getStarted.additionalRequest.sections.additionalInfo.form.emailError'
                                      )
                                    : undefined
                            }
                            onFocus={() => globalLink.handleFieldFocus(t('membership.contact.email'))}
                            autoComplete="email"
                        />

                        <div className="membership-registration-insurance-additional-info-form-content-inline">
                            <div className="membership-registration-insurance-additional-info-form-content-inline-label">
                                <p>
                                    {t(
                                        'membership.getStarted.additionalRequest.sections.additionalInfo.form.contactMethod'
                                    )}
                                </p>
                            </div>
                            <RadioButtonComponent
                                inputName="contactMethod"
                                variation="boxed"
                                isSelected={values.contactMethod === CONTACT_METHOD_FORM.PHONE.toString()}
                                onChange={(e) => handleInputChange('contactMethod', e.target.value)}
                                value={CONTACT_METHOD_FORM.PHONE}
                                label={t('membership.getStarted.additionalRequest.sections.additionalInfo.form.phone')}
                                hasError={errors?.contactMethod ? true : false}
                            />
                            <RadioButtonComponent
                                inputName="contactMethod"
                                variation="boxed"
                                isSelected={values.contactMethod === CONTACT_METHOD_FORM.EMAIL.toString()}
                                onChange={(e) => handleInputChange('contactMethod', e.target.value)}
                                value={CONTACT_METHOD_FORM.EMAIL}
                                label={t('membership.getStarted.additionalRequest.sections.additionalInfo.form.email')}
                                hasError={errors?.contactMethod ? true : false}
                            />
                            {errors?.contactMethod && (
                                <div className="membership-registration-insurance-additional-info-form-content-inline-error">
                                    <span>
                                        {t(
                                            'membership.getStarted.additionalRequest.sections.additionalInfo.form.contactMethodError'
                                        )}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="membership-registration-insurance-additional-info-header">
                    <div>
                        <div className="membership-registration-insurance-additional-info-header-pill"></div>
                        <div className="personal-info-title">
                            {t('membership.getStarted.additionalRequest.sections.insuranceDetails.title')}
                        </div>
                    </div>
                </div>

                <div className="membership-registration-insurance-additional-info-form">
                    <div className="membership-registration-insurance-additional-info-form-copy">
                        <span>{t('membership.getStarted.additionalRequest.sections.insuranceDetails.infoLabel')}</span>
                    </div>
                    <div className="membership-registration-insurance-additional-info-form-content-inline insurance-details">
                        <Text
                            name="insuranceBin"
                            label={t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.bin')}
                            onChange={handleChange}
                            onBlur={(e) => {
                                setFieldTouched('insuranceBin', true);
                                handleBlur(e);
                            }}
                            touched={(!!errors?.insuranceBin || touched.insuranceBin) as boolean}
                            value={values?.insuranceBin}
                            onFocus={() =>
                                globalLink.handleFieldFocus(
                                    t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.bin')
                                )
                            }
                        />
                        <Text
                            name="insuranceGroup"
                            label={t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.group')}
                            onChange={handleChange}
                            onBlur={(e) => {
                                setFieldTouched('insuranceGroup', true);
                                handleBlur(e);
                            }}
                            touched={(!!errors?.insuranceGroup || touched.insuranceGroup) as boolean}
                            value={values?.insuranceGroup}
                            onFocus={() =>
                                globalLink.handleFieldFocus(
                                    t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.group')
                                )
                            }
                        />
                        <Text
                            name="insurancePcn"
                            label={t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.pcn')}
                            onChange={handleChange}
                            onBlur={(e) => {
                                setFieldTouched('insurancePcn', true);
                                handleBlur(e);
                            }}
                            touched={(!!errors?.insurancePcn || touched.insurancePcn) as boolean}
                            value={values?.insurancePcn}
                            onFocus={() =>
                                globalLink.handleFieldFocus(
                                    t('membership.getStarted.additionalRequest.sections.insuranceDetails.form.pcn')
                                )
                            }
                        />
                    </div>
                </div>

                <div className="membership-registration-insurance-additional-info-submit">
                    <Button
                        async
                        label={t('membership.getStarted.additionalRequest.sections.submit.cta')}
                        type="submit"
                        variant="primary"
                        disabled={isBusy}
                        isBusy={isBusy}
                        dataGAFormName="RequestAdditionalInformation"
                        dataGALocation="RequestAdditionalInformation"
                        className="md-full"
                    />
                    <p className="membership-registration-insurance-additional-info-submit-label">
                        {t('membership.getStarted.additionalRequest.sections.submit.disclaimer')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MembershipRegistrationInsuranceAdditionalInfo;
